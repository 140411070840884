div#timeline {
  margin: 50px 0 90px 0;
  color: black;
}

.roadmap.roadmap--orientation-auto .roadmap__navigation > :last-child {
  right: -40px !important;
}

#roadmap .container,
div#timeline,
.footer-wrapper .container,
.container.text-white {
  padding-top: 0 !important;
  border: none !important;
}

.roadmap.roadmap--orientation-auto .roadmap__navigation > :first-child {
  left: -30px !important;
}

li.roadmap__events__event:nth-child(2n) .event__content {
  background: #4e86b1;
}

li.roadmap__events__event:nth-child(2n + 1) .event__content {
  background: #0db2e7 !important;
}

li.roadmap__events__event:nth-child(2n + 3) .event__content {
  background: #fff !important;
  color: #000;
}

.event__date {
  font-family: Monaco;
  padding: 5px;
  margin-bottom: 5px;
}

.event__content {
  color: #fff;
  font-family: Istok Web;
  border: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  padding: 17px 15px;
  border-radius: 10px;
  font-size: 13px;
}

span.timeline-title1 {
  margin-bottom: -10px;
  display: grid;
}

.roadmap.roadmap--orientation-auto .roadmap__navigation > :last-child {
  right: -40px !important;
}

.roadmap.roadmap--orientation-auto .roadmap__navigation > :first-child {
  left: -30px !important;
}

div#timeline {
  margin: 50px 0 90px 0;
}

li.roadmap__events__event:nth-child(2n) .event__content {
  background: #4e86b1;
}

li.roadmap__events__event:nth-child(2n + 1) .event__content {
  background: #0db2e7 !important;
}

li.roadmap__events__event:nth-child(2n + 3) .event__content {
  background: #fff !important;
  color: #000;
}

div#reodmap {
  padding-top: 3em;
  background: #fbfbfb;
  padding-bottom: 5em;
  margin-bottom: 3em;
}

div#market {
  padding-bottom: 0 !important;
}

.rowrap {
  padding-left: 0 !important;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event .event .event__date,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event .event .event__date,
.roadmap.roadmap--orientation-vertical .roadmap__events__event .event .event__date {
  font-weight: 600;
}

.roadmap.roadmap--orientation-auto .roadmap__navigation > * a,
.roadmap.roadmap--orientation-horizontal .roadmap__navigation > * a,
.roadmap.roadmap--orientation-vertical .roadmap__navigation > * a {
  display: block;
  color: #000;
  line-height: 0;
}

.roadmap.roadmap--orientation-auto {
  position: relative;
  width: 95%;
  margin: 0 auto;
}

.roadmap.roadmap--orientation-auto .roadmap__events {
  position: relative;
  width: -webkit-calc(100% - 200px);
  width: calc(100% - 200px);
  margin: 0 auto;
  padding: 200px 0;
}

.roadmap.roadmap--orientation-auto .roadmap__events:after {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  content: '';
  width: 0%;
  height: 4px;
  background-color: #3f81e7;
  border-radius: 2px;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event {
  position: absolute;
  width: 280px;
  height: 200px;
  margin-left: -140px;
  list-style: none;
  font-size: 16px;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:before {
  position: absolute;
  content: '';
  display: block;
  background-color: #3f81e7;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:before {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:after {
  width: 4px;
  height: 0;
  border-radius: 2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.roadmap.roadmap--orientation-auto .roadmap__events__event .event {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.roadmap.roadmap--orientation-auto .roadmap__events__event .event .event__content small {
  display: block;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) {
  bottom: 0;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd):before {
  top: -8px;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd):after {
  top: 0;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) .event {
  top: 80px;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) {
  top: 0;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even):before {
  bottom: -12px;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even):after {
  bottom: -4px;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .event {
  bottom: 80px;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(1n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(1n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(1n):before {
  -webkit-transition-delay: 0.13s;
  -o-transition-delay: 0.13s;
  transition-delay: 0.13s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(2n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(2n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(2n):before {
  -webkit-transition-delay: 0.26s;
  -o-transition-delay: 0.26s;
  transition-delay: 0.26s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(3n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(3n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(3n):before {
  -webkit-transition-delay: 0.39s;
  -o-transition-delay: 0.39s;
  transition-delay: 0.39s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(4n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(4n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(4n):before {
  -webkit-transition-delay: 0.52s;
  -o-transition-delay: 0.52s;
  transition-delay: 0.52s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(5n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(5n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(5n):before {
  -webkit-transition-delay: 0.65s;
  -o-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(6n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(6n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(6n):before {
  -webkit-transition-delay: 0.78s;
  -o-transition-delay: 0.78s;
  transition-delay: 0.78s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(7n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(7n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(7n):before {
  -webkit-transition-delay: 0.91s;
  -o-transition-delay: 0.91s;
  transition-delay: 0.91s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(8n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(8n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(8n):before {
  -webkit-transition-delay: 1.04s;
  -o-transition-delay: 1.04s;
  transition-delay: 1.04s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(9n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(9n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(9n):before {
  -webkit-transition-delay: 1.17s;
  -o-transition-delay: 1.17s;
  transition-delay: 1.17s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(10n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(10n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(10n):before {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(11n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(11n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(11n):before {
  -webkit-transition-delay: 1.43s;
  -o-transition-delay: 1.43s;
  transition-delay: 1.43s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(12n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(12n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(12n):before {
  -webkit-transition-delay: 1.56s;
  -o-transition-delay: 1.56s;
  transition-delay: 1.56s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(13n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(13n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(13n):before {
  -webkit-transition-delay: 1.69s;
  -o-transition-delay: 1.69s;
  transition-delay: 1.69s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(14n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(14n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(14n):before {
  -webkit-transition-delay: 1.82s;
  -o-transition-delay: 1.82s;
  transition-delay: 1.82s;
}

.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(15n) .event,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(15n):after,
.roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(15n):before {
  -webkit-transition-delay: 1.95s;
  -o-transition-delay: 1.95s;
  transition-delay: 1.95s;
}

.roadmap.roadmap--orientation-auto .roadmap__navigation {
  margin: 0;
}

.roadmap.roadmap--orientation-auto .roadmap__navigation > * {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-46%);
  -ms-transform: translateY(-46%);
  -o-transform: translateY(-46%);
  transform: translateY(-46%);
  z-index: 100;
  list-style: none;
}

.roadmap.roadmap--orientation-auto .roadmap__navigation > * a i {
  font-size: 44px;
}

.roadmap.roadmap--orientation-auto .roadmap__navigation > * a:hover {
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.roadmap.roadmap--orientation-auto .roadmap__navigation > :first-child {
  left: 40px;
}

.roadmap.roadmap--orientation-auto .roadmap__navigation > :last-child {
  right: 40px;
}

.roadmap.roadmap--orientation-auto.roadmap--initialized .roadmap__events:after {
  width: 100%;
}

.roadmap.roadmap--orientation-auto.roadmap--initialized .roadmap__events .roadmap__events__event:before {
  -webkit-transform: scale(1) translateX(-50%);
  -ms-transform: scale(1) translateX(-50%);
  -o-transform: scale(1) translateX(-50%);
  transform: scale(1) translateX(-50%);
}

.roadmap.roadmap--orientation-auto.roadmap--initialized .roadmap__events .roadmap__events__event:after {
  height: 62px;
}

.roadmap.roadmap--orientation-auto.roadmap--initialized .roadmap__events .roadmap__events__event .event {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

@media only screen and (max-width: 992px) {
  .roadmap.roadmap--orientation-auto {
    margin: 50px auto;
  }
  .roadmap.roadmap--orientation-auto .roadmap__events {
    padding: 0;
    width: 80%;
    max-width: 640px;
    min-height: 400px;
  }
  .roadmap.roadmap--orientation-auto .roadmap__events:after {
    left: 50%;
    top: 0;
    width: 4px;
    height: -webkit-calc(0% - 0px);
    height: calc(0% - 0px);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .roadmap.roadmap--orientation-auto .roadmap__events__event {
    position: relative;
    left: auto !important;
    top: auto;
    bottom: auto;
    margin-left: 0;
    width: auto;
    height: auto;
    min-height: 120px;
  }
  .roadmap.roadmap--orientation-auto .roadmap__events__event:before {
    top: -8px;
    bottom: auto;
  }
  .roadmap.roadmap--orientation-auto .roadmap__events__event:after {
    top: 0;
    bottom: auto;
    width: 0;
    height: 4px !important;
  }
  .roadmap.roadmap--orientation-auto .roadmap__events__event .event {
    top: -15px;
    bottom: auto;
    width: 40%;
  }
  .roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd):after {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 992px) and (max-width: 600px) {
  .roadmap.roadmap--orientation-auto .roadmap__events__event .event {
    width: 50%;
  }
  .roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) .event {
    margin-left: -10%;
  }
  .roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .event {
    margin-right: -10%;
  }
}

@media only screen and (max-width: 992px) {
  .roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even):after {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  .roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .event {
    right: 0;
  }
  .roadmap.roadmap--orientation-auto.roadmap--initialized .roadmap__events:after {
    width: 4px;
    height: -webkit-calc(100% - 120px);
    height: calc(100% - 120px);
  }
  .roadmap.roadmap--orientation-auto.roadmap--initialized .roadmap__events .roadmap__events__event:after {
    width: 48px;
  }
}

.roadmap.roadmap--orientation-horizontal .roadmap__navigation > :last-child,
.roadmap.roadmap--orientation-vertical .roadmap__navigation > :last-child {
  right: 40px;
}

@media only screen and (max-width: 992px) and (max-width: 600px) {
  .roadmap.roadmap--orientation-auto.roadmap--initialized .roadmap__events .roadmap__events__event:after {
    width: 32px;
  }
}

@media only screen and (max-width: 992px) {
  .roadmap.roadmap--orientation-auto.roadmap--initialized
    .roadmap__events
    .roadmap__events__event:nth-child(odd):after {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .roadmap.roadmap--orientation-auto.roadmap--initialized
    .roadmap__events
    .roadmap__events__event:nth-child(even):after {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

.roadmap.roadmap--orientation-horizontal {
  position: relative;
  width: 95%;
  margin: 0 auto;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events {
  position: relative;
  width: -webkit-calc(100% - 200px);
  width: calc(100% - 200px);
  margin: 0 auto;
  padding: 200px 0;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events:after {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  content: '';
  width: 0%;
  height: 4px;
  background-color: #3f81e7;
  border-radius: 2px;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event {
  position: absolute;
  width: 280px;
  height: 200px;
  margin-left: -140px;
  list-style: none;
  font-size: 16px;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:before {
  position: absolute;
  content: '';
  display: block;
  background-color: #3f81e7;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:before {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:after {
  width: 4px;
  height: 0;
  border-radius: 2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event .event {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event .event .event__content small {
  display: block;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(odd) {
  bottom: 0;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(odd):before {
  top: -8px;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(odd):after {
  top: 0;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(odd) .event {
  top: 80px;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(even) {
  top: 0;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(even):before {
  bottom: -12px;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(even):after {
  bottom: -4px;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(even) .event {
  bottom: 80px;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(1n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(1n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(1n):before {
  -webkit-transition-delay: 0.13s;
  -o-transition-delay: 0.13s;
  transition-delay: 0.13s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(2n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(2n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(2n):before {
  -webkit-transition-delay: 0.26s;
  -o-transition-delay: 0.26s;
  transition-delay: 0.26s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(3n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(3n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(3n):before {
  -webkit-transition-delay: 0.39s;
  -o-transition-delay: 0.39s;
  transition-delay: 0.39s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(4n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(4n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(4n):before {
  -webkit-transition-delay: 0.52s;
  -o-transition-delay: 0.52s;
  transition-delay: 0.52s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(5n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(5n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(5n):before {
  -webkit-transition-delay: 0.65s;
  -o-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(6n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(6n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(6n):before {
  -webkit-transition-delay: 0.78s;
  -o-transition-delay: 0.78s;
  transition-delay: 0.78s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(7n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(7n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(7n):before {
  -webkit-transition-delay: 0.91s;
  -o-transition-delay: 0.91s;
  transition-delay: 0.91s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(8n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(8n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(8n):before {
  -webkit-transition-delay: 1.04s;
  -o-transition-delay: 1.04s;
  transition-delay: 1.04s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(9n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(9n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(9n):before {
  -webkit-transition-delay: 1.17s;
  -o-transition-delay: 1.17s;
  transition-delay: 1.17s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(10n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(10n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(10n):before {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(11n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(11n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(11n):before {
  -webkit-transition-delay: 1.43s;
  -o-transition-delay: 1.43s;
  transition-delay: 1.43s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(12n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(12n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(12n):before {
  -webkit-transition-delay: 1.56s;
  -o-transition-delay: 1.56s;
  transition-delay: 1.56s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(13n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(13n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(13n):before {
  -webkit-transition-delay: 1.69s;
  -o-transition-delay: 1.69s;
  transition-delay: 1.69s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(14n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(14n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(14n):before {
  -webkit-transition-delay: 1.82s;
  -o-transition-delay: 1.82s;
  transition-delay: 1.82s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(15n) .event,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(15n):after,
.roadmap.roadmap--orientation-horizontal .roadmap__events__event:nth-child(15n):before {
  -webkit-transition-delay: 1.95s;
  -o-transition-delay: 1.95s;
  transition-delay: 1.95s;
}

.roadmap.roadmap--orientation-horizontal .roadmap__navigation {
  margin: 0;
}

.roadmap.roadmap--orientation-horizontal .roadmap__navigation > * {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-46%);
  -ms-transform: translateY(-46%);
  -o-transform: translateY(-46%);
  transform: translateY(-46%);
  z-index: 100;
  list-style: none;
}

.roadmap.roadmap--orientation-horizontal .roadmap__navigation > * a i {
  font-size: 44px;
}

.roadmap.roadmap--orientation-horizontal .roadmap__navigation > * a:hover {
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.roadmap.roadmap--orientation-horizontal .roadmap__navigation > :first-child {
  left: 40px;
}

.roadmap.roadmap--orientation-horizontal.roadmap--initialized .roadmap__events:after {
  width: 100%;
}

.roadmap.roadmap--orientation-horizontal.roadmap--initialized .roadmap__events .roadmap__events__event:before {
  -webkit-transform: scale(1) translateX(-50%);
  -ms-transform: scale(1) translateX(-50%);
  -o-transform: scale(1) translateX(-50%);
  transform: scale(1) translateX(-50%);
}

.roadmap.roadmap--orientation-horizontal.roadmap--initialized .roadmap__events .roadmap__events__event:after {
  height: 62px;
}

.roadmap.roadmap--orientation-horizontal.roadmap--initialized .roadmap__events .roadmap__events__event .event {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.roadmap.roadmap--orientation-vertical {
  position: relative;
  width: 95%;
  margin: 50px auto;
}

.roadmap.roadmap--orientation-vertical .roadmap__events {
  position: relative;
  margin: 0 auto;
}

.roadmap.roadmap--orientation-vertical .roadmap__events:after {
  position: absolute;
  display: block;
  content: '';
  background-color: #3f81e7;
  border-radius: 2px;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event {
  list-style: none;
  font-size: 16px;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:before {
  position: absolute;
  content: '';
  display: block;
  background-color: #3f81e7;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:before {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:after {
  border-radius: 2px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event .event {
  position: absolute;
  display: block;
  text-align: center;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  -o-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1.3);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event .event .event__content small {
  display: block;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(odd) {
  bottom: 0;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(odd):before {
  top: -8px;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(odd):after {
  top: 0;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(odd) .event {
  top: 80px;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even) {
  top: 0;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even):before {
  bottom: -12px;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even):after {
  bottom: -4px;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even) .event {
  bottom: 80px;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(1n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(1n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(1n):before {
  -webkit-transition-delay: 0.13s;
  -o-transition-delay: 0.13s;
  transition-delay: 0.13s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(2n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(2n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(2n):before {
  -webkit-transition-delay: 0.26s;
  -o-transition-delay: 0.26s;
  transition-delay: 0.26s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(3n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(3n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(3n):before {
  -webkit-transition-delay: 0.39s;
  -o-transition-delay: 0.39s;
  transition-delay: 0.39s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(4n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(4n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(4n):before {
  -webkit-transition-delay: 0.52s;
  -o-transition-delay: 0.52s;
  transition-delay: 0.52s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(5n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(5n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(5n):before {
  -webkit-transition-delay: 0.65s;
  -o-transition-delay: 0.65s;
  transition-delay: 0.65s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(6n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(6n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(6n):before {
  -webkit-transition-delay: 0.78s;
  -o-transition-delay: 0.78s;
  transition-delay: 0.78s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(7n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(7n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(7n):before {
  -webkit-transition-delay: 0.91s;
  -o-transition-delay: 0.91s;
  transition-delay: 0.91s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(8n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(8n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(8n):before {
  -webkit-transition-delay: 1.04s;
  -o-transition-delay: 1.04s;
  transition-delay: 1.04s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(9n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(9n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(9n):before {
  -webkit-transition-delay: 1.17s;
  -o-transition-delay: 1.17s;
  transition-delay: 1.17s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(10n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(10n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(10n):before {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(11n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(11n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(11n):before {
  -webkit-transition-delay: 1.43s;
  -o-transition-delay: 1.43s;
  transition-delay: 1.43s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(12n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(12n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(12n):before {
  -webkit-transition-delay: 1.56s;
  -o-transition-delay: 1.56s;
  transition-delay: 1.56s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(13n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(13n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(13n):before {
  -webkit-transition-delay: 1.69s;
  -o-transition-delay: 1.69s;
  transition-delay: 1.69s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(14n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(14n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(14n):before {
  -webkit-transition-delay: 1.82s;
  -o-transition-delay: 1.82s;
  transition-delay: 1.82s;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(15n) .event,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(15n):after,
.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(15n):before {
  -webkit-transition-delay: 1.95s;
  -o-transition-delay: 1.95s;
  transition-delay: 1.95s;
}

.roadmap.roadmap--orientation-vertical .roadmap__navigation {
  margin: 0;
}

.roadmap.roadmap--orientation-vertical .roadmap__navigation > * {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-46%);
  -ms-transform: translateY(-46%);
  -o-transform: translateY(-46%);
  transform: translateY(-46%);
  z-index: 100;
  list-style: none;
}

.roadmap.roadmap--orientation-vertical .roadmap__navigation > * a i {
  font-size: 44px;
}

.roadmap.roadmap--orientation-vertical .roadmap__navigation > * a:hover {
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.roadmap.roadmap--orientation-vertical .roadmap__navigation > :first-child {
  left: 40px;
}

.roadmap.roadmap--orientation-vertical.roadmap--initialized .roadmap__events .roadmap__events__event:before {
  -webkit-transform: scale(1) translateX(-50%);
  -ms-transform: scale(1) translateX(-50%);
  -o-transform: scale(1) translateX(-50%);
  transform: scale(1) translateX(-50%);
}

.roadmap.roadmap--orientation-vertical.roadmap--initialized .roadmap__events .roadmap__events__event:after {
  height: 62px;
}

.roadmap.roadmap--orientation-vertical.roadmap--initialized .roadmap__events .roadmap__events__event .event {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.roadmap.roadmap--orientation-vertical .roadmap__events {
  padding: 0;
  width: 80%;
  max-width: 640px;
  min-height: 400px;
}

.roadmap.roadmap--orientation-vertical .roadmap__events:after {
  left: 50%;
  top: 0;
  width: 4px;
  height: -webkit-calc(0% - 0px);
  height: calc(0% - 0px);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event {
  position: relative;
  left: auto !important;
  top: auto;
  bottom: auto;
  margin-left: 0;
  width: auto;
  height: auto;
  min-height: 120px;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:before {
  top: -8px;
  bottom: auto;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:after {
  top: 0;
  bottom: auto;
  width: 0;
  height: 4px !important;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event .event {
  top: -15px;
  bottom: auto;
  width: 40%;
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(odd):after {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even):after {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even) .event {
  right: 0;
}

@media only screen and (max-width: 600px) {
  .roadmap.roadmap--orientation-vertical .roadmap__events__event .event {
    width: 50%;
  }
  .roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(odd) .event {
    margin-left: -10%;
  }
  .roadmap.roadmap--orientation-vertical .roadmap__events__event:nth-child(even) .event {
    margin-right: -10%;
  }
}

.roadmap.roadmap--orientation-vertical.roadmap--initialized .roadmap__events:after {
  width: 4px;
  height: -webkit-calc(100% - 120px);
  height: calc(100% - 120px);
}

.roadmap.roadmap--orientation-vertical.roadmap--initialized .roadmap__events .roadmap__events__event:after {
  width: 48px;
}

@media only screen and (max-width: 600px) {
  .roadmap.roadmap--orientation-vertical.roadmap--initialized .roadmap__events .roadmap__events__event:after {
    width: 32px;
  }
}

.roadmap.roadmap--orientation-vertical.roadmap--initialized
  .roadmap__events
  .roadmap__events__event:nth-child(odd):after {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.roadmap.roadmap--orientation-vertical.roadmap--initialized
  .roadmap__events
  .roadmap__events__event:nth-child(even):after {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

@media (max-width: 991px) {
  .roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(odd) .event,
  .roadmap.roadmap--orientation-auto .roadmap__events__event:nth-child(even) .event {
    top: -60px;
  }
}

@media (max-width: 500px) {
  .roadmap.roadmap--orientation-auto .roadmap__navigation > :last-child {
    right: -46px !important;
  }
  .roadmap.roadmap--orientation-auto .roadmap__navigation > :first-child {
    left: -50px !important;
  }
}
