/* =================================================================== */
/* ----------------------------- Tozex Launch ---------------------------- */
/* =================================================================== */

.tz-bro {
  width: 100%;
  min-height: calc(100vh - 72px);
  margin-top: 72px;
  background-color: var(--clr-dark);
  font-size: 0.875rem;

  a {
    color: var(--clr-blue-dark) !important;
  }

  &__inner {
    background-color: var(--clr-primary-darker);
    border-radius: 0.25rem;
    width: 100%;
    overflow-y: auto;

    &__header {
      height: 33px;
      background-color: var(--clr-primary-dark);
      border-bottom: 1px solid var(--clr-primary);
      border-radius: 0.25rem 0.25rem 0 0;
      font-weight: 500;
      line-height: 2rem;
      color: var(--clr-primary-lighter);

      &__tab {
        height: 32px;
        display: inline-block;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &.active {
          border-color: var(--clr-white);
          color: var(--clr-white);
        }

        &:hover {
          color: var(--clr-white);
        }
      }
    }

    .scroll-top-down {
      position: absolute;
      bottom: 80px;
      right: 60px;
      z-index: 10;
      display: inline-block;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      color: #343a40;
      font: normal 400 20px/1 'Josefin Sans', sans-serif;
      letter-spacing: 0.1em;
      text-decoration: none;
      transition: opacity 0.3s;

      span {
        position: absolute;
        top: 0;
        left: 50%;
        width: 46px;
        height: 46px;
        margin-left: -23px;
        border: 1px solid #2b2b2b;
        border-radius: 100%;
        box-sizing: border-box;
        background-color: white;
      }

      span::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        content: '';
        width: 44px;
        height: 44px;
        box-shadow: 0 0 0 0 #2b2b2b;
        border-radius: 100%;
        opacity: 0;
        -webkit-animation: sdb03 3s infinite;
        animation: sdb03 3s infinite;
        box-sizing: border-box;
      }
      @-webkit-keyframes sdb03 {
        0% {
          opacity: 0;
        }
        30% {
          opacity: 1;
        }
        60% {
          box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }
      @keyframes sdb03 {
        0% {
          opacity: 0;
        }
        30% {
          opacity: 1;
        }
        60% {
          box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }
    }

    .scroll-top-down.upward {
      display: none;

      span::after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        width: 16px;
        height: 16px;
        margin: -4px 0 0 -8px;
        border-left: 1px solid #2b2b2b;
        border-bottom: 1px solid #2b2b2b;
        -webkit-transform: rotate(-45deg);
        transform: rotate(135deg);
        box-sizing: border-box;
      }
    }

    .scroll-top-down.downward {
      display: inline-block;

      span::after {
        position: absolute;
        top: 50%;
        left: 50%;
        content: '';
        width: 16px;
        height: 16px;
        margin: -12px 0 0 -8px;
        border-left: 1px solid #2b2b2b;
        border-bottom: 1px solid #2b2b2b;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        box-sizing: border-box;
      }
    }
  }

  &__sidemenu {
    background-color: var(--clr-primary-darker);
    border-radius: 0.25rem;
    height: 100%;
    padding: 12px;

    &__list {
      width: 100%;
      text-align: left;

      &__item {
        line-height: 60px;
        padding-left: 20px;
        border-radius: 3px;

        font-size: 18px;
        -webkit-transition: color 0.2s ease-in-out;
        -o-transition: color 0.2s ease-in-out;
        transition: color 0.2s ease-in-out;

        span,
        a {
          padding: 0.5rem 1rem;
          display: block;
          font-weight: 500;
          color: var(--clr-primary-light);
        }
      }

      &__item:hover {
        cursor: pointer;
        background-color: var(--clr-primary-dark);

        span,
        a {
          color: var(--clr-white);
        }
      }

      &__item.active {
        cursor: pointer;
        background-color: var(--clr-primary-dark);

        span,
        a {
          color: var(--clr-white);
        }
      }
    }
  }

  &__new {
    padding: 5rem 3rem 3rem;
    background-color: var(--clr-blue-gray);
    border-top: 1px solid var(--clr-gray);
    height: -webkit-fill-available;
    min-height: 100%;
    overflow: auto;

    &__title {
      font-size: 2rem;
      font-weight: 400;
    }

    &__list {
      list-style-type: none;

      &__item {
        margin-top: 1rem;
        color: var(--clr-gray);
      }

      &__icon {
        width: 24px;
        height: 24px;
        margin-right: 1rem;
        fill: var(--clr-primary);
      }
    }

    button span {
      font-size: 14px;
    }

    &__notification {
      background-color: #c3f1f8;
      border: 1px solid #3d6ed0;
      border-radius: 3px;
    }

    &__choice-radio {
      margin: 10px;
      padding: 50px;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      border-radius: 10px;
      line-height: 20px;

      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    }

    &__choice-radio:hover {
      cursor: pointer;
      color: white;
      background-color: var(--clr-primary-darker);
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.49), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.35);
    }

    &__multisig-owners-list {
      .owner-container {
        background-color: #efefef;
        padding: 4px 10px;
        border-radius: 2px;
        max-width: max-content;

        .owner {
          color: var(--clr-primary);
        }
      }

      .close {
        font-size: 14px;
        color: black;
        cursor: pointer;
      }
    }

    &__download {
      max-width: 200px;

      &__icon {
        width: 24px;
        height: 24px;
        margin-left: 0.5rem;
        fill: var(--clr-white);
      }
    }

    &__img {
      width: 100%;
    }

    p {
      margin: 0;
    }

    span {
      font-size: 17px;
    }

    .clipboard-textbox {
      display: flex;
      width: 100%;
      margin-top: 30px;
      font-size: 14px;

      .copy-clipboard-input {
        width: 50%;
      }

      .copy-clipboard-button {
        margin-top: 2px;
        background-color: #001b57;
        padding: 14px 16px;
        border: 1px solid #001b57;
        box-shadow: none;
        color: white;
      }

      .snackBarContainer {
        position: relative;
        left: 0 !important;
        right: 0 !important;
        transform: unset;
        margin-left: 20px;
        color: green;
        background-color: transparent;

        div {
          background-color: green;
        }

        button {
          padding: 0;
        }

        span {
          font-size: 16px;
        }
      }

      span {
        font-size: 14px;
      }

      span.copy-clipboard-state {
        margin-left: 10px;
      }
    }
  }

  &__manage {
    padding: 5rem 3rem 3rem;
    background-color: var(--clr-white);
    border-top: 1px solid var(--clr-gray);
    height: -webkit-fill-available;
    min-height: 100%;
    overflow: auto;

    &__contract-address {
      color: black !important;
    }

    &__container {
      .manage-container {
        flex: 5;
      }

      .summary-container {
        flex: 3;
      }

      .snackBarContainer {
        position: absolute;
        top: 0 !important;
        right: 0 !important;
        transform: unset;
        margin-right: 20px;
        color: green;
        background-color: transparent;

        div {
          background-color: green;
        }

        button {
          padding: 0;
        }

        span {
          font-size: 16px;
        }
      }
    }

    &_erc20 {
      padding: 0 15px !important;
    }
  }

  &__mc {
    padding: 5rem 3rem 3rem;
    background-color: var(--clr-white);
    border-top: 1px solid var(--clr-gray);
    height: -webkit-fill-available;
    min-height: 100%;
    overflow: auto;

    &__choice-radio {
      margin: 10px;
      padding: 40px;
      //border: 1px solid #1c2030;
      text-align: center;
      font-weight: 400;

      border-radius: 10px;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);

      img {
        margin: 5px;
      }
    }

    &__choice-radio:hover {
      cursor: pointer;
      color: white;
      background-color: var(--clr-primary-darker);
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.49), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.35);
    }
  }

  &__buy {
    padding: 5rem 3rem 3rem;
    background-color: var(--clr-white);
    border-top: 1px solid var(--clr-gray);
    height: -webkit-fill-available;
    min-height: 100%;
    overflow: auto;

    &__title {
      font-size: 2rem;
      font-weight: 400;
    }
    .tz-launch__sub__content {
      margin: 60px 10px 10px 10px;
    }
  }

  &__notification {
    color: red;
  }

  &__sub__content {
    margin-left: 10px;
  }

  &__sub__title {
    margin-left: 10px;
    font-size: 28px;
    color: var(--clr-blue-dark);
  }
}

.tozex-table__paper {
  table {
    th {
      padding: 4px 12px;
    }
  }
}

.tz-dialog {
  a {
    color: #0db2e7 !important;
  }
}

.no-scroll {
  overflow: hidden;
  height: calc(100vh - 80px);
}

.d-none {
  display: none !important;
}

.w-100 {
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .tz-bro {
    &__sidemenu {
      background-color: #002467;
      margin-top: 80px;

      &__list__item a {
        padding: 8px 16px !important;
      }
    }

    &__new {
      padding: 48px 0 32px 0;

      &__title {
        margin-top: 50px;
      }

      .choose-contract-container > div {
        display: block !important;
      }

      &__choice-radio {
        align-items: center;
        min-height: 72px;
        display: flex;
        justify-content: center;
        padding: 5px;
        margin: 20px;
      }

      .tz-home__ga__title {
        margin-top: 70px;
      }

      .clipboard-textbox {
        .copy-clipboard-input {
          width: 70%;
        }
        .snackBarContainer {
          position: fixed;
          margin: 0;
        }
      }

      .invite-friend-container {
        h2,
        span {
          text-align: left;
          display: flex;
        }
        .clipboard-textbox {
          display: flex;
          width: 100%;
          margin-top: 30px;
          font-size: 14px;

          .copy-clipboard-input {
            width: 100%;
          }

          .copy-clipboard-button {
            margin-top: 0px;
            background-color: #fff;
            padding: 16px 16px;
            border: 1px solid rgba(0, 0, 0, 0.23);
            box-shadow: none;
            color: white;

            img {
              width: 20px;
            }
          }

          span {
            font-size: 14px;
          }

          span.copy-clipboard-state {
            margin-left: 10px;
          }
        }
      }
    }

    &__mc {
      padding: 48px 0 32px 0;

      .choose-block-chain {
        display: block !important;
      }
      &__choice-radio {
        margin: 20px;
        padding: 24px 10px;
        margin-top: 20px;
      }
    }

    &__manage {
      &_erc20 {
        padding: 0 15px !important;
        margin: 0 20px;
      }

      &__container {
        .clipboard-textbox {
          display: flex;
          width: 100%;
          margin-top: 30px;
          font-size: 14px;

          .copy-clipboard-input {
            width: 100%;
          }

          .copy-clipboard-button {
            margin-top: 0px;
            background-color: #fff;
            padding: 16px 16px;
            border: 1px solid rgba(0, 0, 0, 0.23);
            box-shadow: none;
            color: white;

            img {
              width: 20px;
            }
          }

          span {
            font-size: 14px;
          }

          span.copy-clipboard-state {
            margin-left: 10px;
          }
        }
      }
    }

    &__buy {
      padding: 48px 0 32px 0;

      .tz-launch__sub__content {
        margin: 70px 20px 20px 20px;
      }

      .tz-carbon {
        .carbon-fiber__container {
          width: 100%;
        }
      }
    }

    &__sub__content {
      margin: 15px 20px;
    }

    &__sub__title {
      margin: 50px 20px 48px 20px;
    }
  }
  .tz-exchange {
    .mobile-menu.tz-side-menu {
      padding: 0;
    }
  }
  .col-sm-w-100 {
    width: 100% !important;
  }
  .col-sm-d-none {
    display: none !important;
  }
  .col-sm-d-block {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {
  .tz-bro {
    &__new {
      &__choice-radio {
        flex-direction: column;
        padding: 5px;
        margin: 20px 10px;
      }

      &_multisig {
        .owner-addr-container {
          flex-direction: column !important;

          .tz-exchange__trade__input {
            margin-bottom: 20px;
          }

          button {
            margin: 0 auto !important;
            width: 100%;
          }
        }
      }
    }

    &__mc {
      &__choice-radio {
        margin: 20px 10px;
      }

      .container {
        padding: 0 15px;
      }
      .tz-launch__manage_erc20 {
        margin: 0;
        padding: 0 15px !important;
      }
    }

    &__buy {
      .tz-launch__sub__content {
        margin: 70px 10px 10px 10px;
      }
    }

    &__sub__title {
      margin: 50px 10px 48px 10px;
    }

    &__sub__content {
      margin: 10px;
    }
  }
  .col-xs-w-100 {
    width: 100% !important;
  }
  .col-xs-d-none {
    display: none !important;
  }
  .col-xs-d-block {
    display: block !important;
  }
}
