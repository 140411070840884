/* =================================================================== */
/* ----------------------------- Utilities --------------------------- */
/* =================================================================== */

/* ========== Flex grid ========== */
.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}
