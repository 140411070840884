/* =================================================================== */
/* ----------------------------- Tozex Account ----------------------- */
/* =================================================================== */

.tz-account {
  width: 100%;
  //min-height: calc(100vh - 72px);
  margin-top: 72px;
  background-color: var(--clr-blue-gray);
  font-size: 0.875rem;
  min-height: 100vh;

  &__history {
    .container {
      padding: 0;
      margin-top: 24px;
    }
    p {
      margin: 0;
    }

    span {
      font-size: 20px;
    }
  }

  &__body {
    display: flex;
    overflow-x: auto;
  }
}

.investor {
  .align-items-center {
    align-items: center;
  }
  p {
    font-size: 16px;
    margin: 0;
  }
  .project-list {
    p {
      font-size: 14px;
      margin: 0;
    }
  }
  .check-list {
    p {
      margin: 0;
    }
  }
}

.justify-space-between {
  justify-content: space-between;
}
