/* =================================================================== */
/* ----------------------------- Homepage ---------------------------- */
/* =================================================================== */

.tz-home {
  width: 100%;
  height: calc(100vh - 72px);
  margin-top: 72px;

  &__banner {
    padding: 8rem 0 4rem;
    background: var(--clr-white);
    position: relative;
    background-position: 100% 70%;
    background-size: 70%;
    background-repeat: no-repeat;

    &__title {
      max-width: 400px;
      font-size: 2.5rem;
      color: var(--clr-white);
    }

    .blue {
      color: var(--clr-second) !important;
    }

    &__description {
      max-width: 600px;
      color: var(--clr-primary-lighter);
    }

    &__auth {
      border-color: var(--clr-primary-lighter) !important;
      color: var(--clr-primary-lighter) !important;

      &:hover {
        background-color: var(--clr-primary) !important;
        border-color: var(--clr-primary) !important;
        color: var(--clr-white) !important;
      }
    }

    &__bg {
      width: 100%;
      max-height: 450px;
    }

    &__slider-item {
      padding: 2rem 1rem;
      overflow: visible;

      img {
        width: 300px;
        margin: 0 auto;
        transition: all 300ms;

        &:hover {
          transform: translateY(-1rem);
        }
      }
    }
  }

  &__gl {
    padding: 5rem 3rem;
    background-color: var(--clr-white);

    &__title {
      font-size: 2.5rem;
      font-weight: 600;
    }

    &__img {
      max-width: 70%;
    }

    &__item {
      transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border: 1px solid transparent;
    }

    &__item:hover {
      box-shadow: 0 0 40px rgba(74, 195, 243, 0.6);
      -webkit-box-shadow: 0 0 40px rgba(74, 195, 243, 0.6);
      border: 1px solid #aeaeae;
      cursor: pointer;
    }

    .description {
      display: block;
      margin-bottom: 0;
      font-size: 18px;
      line-height: 1.7;
      font-family: Monaco;
    }

    &__content {
      &__title {
        font-size: 1.5rem;
        font-weight: 400;
      }

      &__description {
        color: var(--clr-gray);
      }
    }

    &__icon {
      width: 48px;
      height: 48px;
    }
  }

  &__platform {
    padding: 5rem 3rem;
    background-color: var(--clr-main);

    &__title {
      font-size: 2.5rem;
      font-weight: 600;
    }

    &__content {
      font-family: Monaco;

      &__title {
        font-size: 1.8rem;
      }

      &__left {
        color: var(--clr-white);
        font-family: Monaco;
      }
    }
  }

  &__mp {
    padding: 5rem 3rem;
    background-color: var(--clr-white);

    &__title {
      font-size: 2.5rem;
      font-weight: 600;
    }

    &__content {
      &__img {
        max-width: 100%;
      }
    }
  }

  &__bro {
    padding: 3rem 1rem;
    background-color: var(--clr-second);

    &__title {
      font-size: 2.5rem;
      font-weight: 600;
    }

    &__content {
      font-family: Monaco;
      color: var(--clr-white);

      &__title {
        font-size: 1.8rem;
      }

      &__left {
        color: var(--clr-white);
        font-family: Monaco;
      }

      &__chart {
        width: 100%;
      }
    }
  }

  &__fs {
    padding: 3rem 1rem;
    background-color: var(--clr-white);

    &__title {
      font-size: 2.5rem;
      font-weight: 600;
    }

    &__content {
      font-family: Monaco;
      color: var(--clr-white);

      &__title {
        font-size: 1.8rem;
      }

      &__chart {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;

        .chart-content {
          width: 100%;
        }

        .button-content {
          display: flex;
          flex-direction: column;
          position: absolute;
        }
      }
    }
  }

  &__rm {
    padding: 3rem 1rem;
    background-color: var(--clr-white);

    &__title {
      font-size: 2.5rem;
      font-weight: 600;
    }

    &__content {
      font-family: Monaco;
      color: var(--clr-white);
    }
  }

  &__ts {
    padding: 3rem 1rem;
    background-color: var(--clr-white);

    &__title {
      font-size: 2.5rem;
      font-weight: 600;
    }

    &__content {
      font-family: Monaco;
      color: var(--clr-white);

      .member-container {
        width: 250px;
        margin: 10px;
        padding: 20px;
        border: 1px solid #a6a6a6;
        border-radius: 2px;

        .member-image {
          width: 150px;
          margin: 0 auto;
          margin-bottom: 20px;

          img {
            max-width: 100%;
            height: auto;
          }
        }

        .member-info {
          margin-bottom: 10px;
          .member-name {
            margin-bottom: 3px;
            font-family: Monaco;
            font-size: 15px;
            color: black;
          }

          .member-job {
            font-size: 13px;
            font-family: Monaco;
            color: #4ac4f3;
          }
        }

        .member-social {
          a {
            color: #504b4b;
            margin: 5px;
            font-size: 14px;
            border: 1px solid;
            padding: 6px;
            border-radius: 3px;
          }
        }
      }

      .member-container:hover {
        cursor: pointer;
        box-shadow: 0 0 16px 0px var(--clr-second);
        transition: all 0.5s ease-in-out;
      }
    }
  }

  &__ga {
    padding: 5rem 3rem 0;
    background-color: var(--clr-white);
    border-top: 1px solid var(--clr-gray);

    &__title {
      max-width: 450px;
      font-size: 2rem;
      font-weight: 400;
    }

    &__list {
      list-style-type: none;

      &__item {
        margin-top: 1rem;
        color: var(--clr-gray);
      }

      &__icon {
        width: 24px;
        height: 24px;
        margin-right: 1rem;
        fill: var(--clr-primary);
      }
    }

    &__download {
      &__icon {
        width: 24px;
        height: 24px;
        margin-left: 0.5rem;
        fill: var(--clr-white);
      }
    }

    &__img {
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    &__banner {
      &__title {
        max-width: 100%;
        font-size: 2rem;
        line-height: 1.2;
      }

      &__description {
        max-width: 100%;
      }
    }

    &__gl,
    &__ga {
      &__title {
        font-size: 1.5rem;
        line-height: 1.2;
      }
    }

    &__gl {
      &__content__title {
        font-size: 1.3rem;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &__banner,
    &__gl {
      padding: 3rem 1rem 1rem;
    }

    &__ga {
      padding: 3rem 1rem 0;
    }

    &__ga {
      &__list__item {
        text-align: left;
      }
    }
  }

  @media screen and (max-width: 396px) {
    &__banner {
      &__slider-item img {
        width: 100% !important;
      }
    }
  }
}

.btn-bro-pre-registration {
  &:hover {
    span {
      display: block;
    }
  }

  span {
    display: none;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    min-width: 500px;
    transform: translateX(-25%);
  }
}
