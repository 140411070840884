.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.mt-8 {
  margin-top: 6rem !important;
}

.mt-9 {
  margin-top: 8rem !important;
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.mb-7 {
  margin-bottom: 5rem !important;
}

.mb-8 {
  margin-bottom: 6rem !important;
}

.mb-9 {
  margin-bottom: 8rem !important;
}

.pt-6 {
  padding-top: 4rem !important;
}

.pt-7 {
  padding-top: 5rem !important;
}

.pt-8 {
  padding-top: 6rem !important;
}

.pt-9 {
  padding-top: 8rem !important;
}

.pb-6 {
  padding-bottom: 4rem !important;
}

.pb-7 {
  padding-bottom: 5rem !important;
}

.pb-8 {
  padding-bottom: 6rem !important;
}

.pb-9 {
  padding-bottom: 8rem !important;
}

.m-sm {
  margin: 2rem !important;
}

.mt-sm,
.my-sm {
  margin-top: 2rem !important;
}

.mr-sm,
.mx-sm {
  margin-right: 2rem !important;
}

.mb-sm,
.my-sm {
  margin-bottom: 2rem !important;
}

.ml-sm,
.mx-sm {
  margin-left: 2rem !important;
}

.m-md {
  margin: 4rem !important;
}

.mt-md,
.my-md {
  margin-top: 4rem !important;
}

.mr-md,
.mx-md {
  margin-right: 4rem !important;
}

.mb-md,
.my-md {
  margin-bottom: 4rem !important;
}

.ml-md,
.mx-md {
  margin-left: 4rem !important;
}

.m-lg {
  margin: 6rem !important;
}

.mt-lg,
.my-lg {
  margin-top: 6rem !important;
}

.mr-lg,
.mx-lg {
  margin-right: 6rem !important;
}

.mb-lg,
.my-lg {
  margin-bottom: 6rem !important;
}

.ml-lg,
.mx-lg {
  margin-left: 6rem !important;
}

.m-xl {
  margin: 8rem !important;
}

.mt-xl,
.my-xl {
  margin-top: 8rem !important;
}

.mr-xl,
.mx-xl {
  margin-right: 8rem !important;
}

.mb-xl,
.my-xl {
  margin-bottom: 8rem !important;
}

.ml-xl,
.mx-xl {
  margin-left: 8rem !important;
}

.p-sm {
  padding: 2rem !important;
}

.pt-sm,
.py-sm {
  padding-top: 2rem !important;
}

.pr-sm,
.px-sm {
  padding-right: 2rem !important;
}

.pb-sm,
.py-sm {
  padding-bottom: 2rem !important;
}

.pl-sm,
.px-sm {
  padding-left: 2rem !important;
}

.p-md {
  padding: 4rem !important;
}

.pt-md,
.py-md {
  padding-top: 4rem !important;
}

.pr-md,
.px-md {
  padding-right: 4rem !important;
}

.pb-md,
.py-md {
  padding-bottom: 4rem !important;
}

.pl-md,
.px-md {
  padding-left: 4rem !important;
}

.p-lg {
  padding: 6rem !important;
}

.pt-lg,
.py-lg {
  padding-top: 6rem !important;
}

.pr-lg,
.px-lg {
  padding-right: 6rem !important;
}

.pb-lg,
.py-lg {
  padding-bottom: 6rem !important;
}

.pl-lg,
.px-lg {
  padding-left: 6rem !important;
}

.p-xl {
  padding: 8rem !important;
}

.pt-xl,
.py-xl {
  padding-top: 8rem !important;
}

.pr-xl,
.px-xl {
  padding-right: 8rem !important;
}

.pb-xl,
.py-xl {
  padding-bottom: 8rem !important;
}

.pl-xl,
.px-xl {
  padding-left: 8rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (max-width: 767px) {
  .m-xs {
    margin: 2rem !important;
  }
  .mt-xs,
  .my-xs {
    margin-top: 2rem !important;
  }
  .mr-xs,
  .mx-xs {
    margin-right: 2rem !important;
  }
  .mb-xs,
  .my-xs {
    margin-bottom: 2rem !important;
  }
  .ml-xs,
  .mx-xs {
    margin-left: 2rem !important;
  }
  .m-md {
    margin: 4rem !important;
  }
  .mt-md,
  .my-md {
    margin-top: 4rem !important;
  }
  .mr-md,
  .mx-md {
    margin-right: 4rem !important;
  }
  .mb-md,
  .my-md {
    margin-bottom: 4rem !important;
  }
  .ml-md,
  .mx-md {
    margin-left: 4rem !important;
  }
  .m-lg {
    margin: 6rem !important;
  }
  .mt-lg,
  .my-lg {
    margin-top: 6rem !important;
  }
  .mr-lg,
  .mx-lg {
    margin-right: 6rem !important;
  }
  .mb-lg,
  .my-lg {
    margin-bottom: 6rem !important;
  }
  .ml-lg,
  .mx-lg {
    margin-left: 6rem !important;
  }
  .m-xl {
    margin: 8rem !important;
  }
  .mt-xl,
  .my-xl {
    margin-top: 8rem !important;
  }
  .mr-xl,
  .mx-xl {
    margin-right: 8rem !important;
  }
  .mb-xl,
  .my-xl {
    margin-bottom: 8rem !important;
  }
  .ml-xl,
  .mx-xl {
    margin-left: 8rem !important;
  }
  .p-xs {
    padding: 2rem !important;
  }
  .pt-xs,
  .py-xs {
    padding-top: 2rem !important;
  }
  .pr-xs,
  .px-xs {
    padding-right: 2rem !important;
  }
  .pb-xs,
  .py-xs {
    padding-bottom: 2rem !important;
  }
  .pl-xs,
  .px-xs {
    padding-left: 2rem !important;
  }
  .p-xs-md {
    padding: 4rem !important;
  }
  .pt-xs-md,
  .py-xs-md {
    padding-top: 4rem !important;
  }
  .pr-xs-md,
  .px-xs-md {
    padding-right: 4rem !important;
  }
  .pb-xs-md,
  .py-xs-md {
    padding-bottom: 4rem !important;
  }
  .pl-xs-md,
  .px-xs-md {
    padding-left: 4rem !important;
  }
  .p-xs-lg {
    padding: 6rem !important;
  }
  .pt-xs-lg,
  .py-xs-lg {
    padding-top: 6rem !important;
  }
  .pr-xs-lg,
  .px-xs-lg {
    padding-right: 6rem !important;
  }
  .pb-xs-lg,
  .py-xs-lg {
    padding-bottom: 6rem !important;
  }
  .pl-xs-lg,
  .px-xs-lg {
    padding-left: 6rem !important;
  }
  .p-xs-xl {
    padding: 8rem !important;
  }
  .pt-xs-xl,
  .py-xs-xl {
    padding-top: 8rem !important;
  }
  .pr-xs-xl,
  .px-xs-xl {
    padding-right: 8rem !important;
  }
  .pb-xs-xl,
  .py-xs-xl {
    padding-bottom: 8rem !important;
  }
  .pl-xs-xl,
  .px-xs-xl {
    padding-left: 8rem !important;
  }
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-sm {
    margin: 2rem !important;
  }
  .mt-sm-sm,
  .my-sm-sm {
    margin-top: 2rem !important;
  }
  .mr-sm-sm,
  .mx-sm-sm {
    margin-right: 2rem !important;
  }
  .mb-sm-sm,
  .my-sm-sm {
    margin-bottom: 2rem !important;
  }
  .ml-sm-sm,
  .mx-sm-sm {
    margin-left: 2rem !important;
  }
  .m-sm-md {
    margin: 4rem !important;
  }
  .mt-sm-md,
  .my-sm-md {
    margin-top: 4rem !important;
  }
  .mr-sm-md,
  .mx-sm-md {
    margin-right: 4rem !important;
  }
  .mb-sm-md,
  .my-sm-md {
    margin-bottom: 4rem !important;
  }
  .ml-sm-md,
  .mx-sm-md {
    margin-left: 4rem !important;
  }
  .m-sm-lg {
    margin: 6rem !important;
  }
  .mt-sm-lg,
  .my-sm-lg {
    margin-top: 6rem !important;
  }
  .mr-sm-lg,
  .mx-sm-lg {
    margin-right: 6rem !important;
  }
  .mb-sm-lg,
  .my-sm-lg {
    margin-bottom: 6rem !important;
  }
  .ml-sm-lg,
  .mx-sm-lg {
    margin-left: 6rem !important;
  }
  .m-sm-xl {
    margin: 8rem !important;
  }
  .mt-sm-xl,
  .my-sm-xl {
    margin-top: 8rem !important;
  }
  .mr-sm-xl,
  .mx-sm-xl {
    margin-right: 8rem !important;
  }
  .mb-sm-xl,
  .my-sm-xl {
    margin-bottom: 8rem !important;
  }
  .ml-sm-xl,
  .mx-sm-xl {
    margin-left: 8rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-sm {
    padding: 2rem !important;
  }
  .pt-sm-sm,
  .py-sm-sm {
    padding-top: 2rem !important;
  }
  .pr-sm-sm,
  .px-sm-sm {
    padding-right: 2rem !important;
  }
  .pb-sm-sm,
  .py-sm-sm {
    padding-bottom: 2rem !important;
  }
  .pl-sm-sm,
  .px-sm-sm {
    padding-left: 2rem !important;
  }
  .p-sm-md {
    padding: 4rem !important;
  }
  .pt-sm-md,
  .py-sm-md {
    padding-top: 4rem !important;
  }
  .pr-sm-md,
  .px-sm-md {
    padding-right: 4rem !important;
  }
  .pb-sm-md,
  .py-sm-md {
    padding-bottom: 4rem !important;
  }
  .pl-sm-md,
  .px-sm-md {
    padding-left: 4rem !important;
  }
  .p-sm-lg {
    padding: 6rem !important;
  }
  .pt-sm-lg,
  .py-sm-lg {
    padding-top: 6rem !important;
  }
  .pr-sm-lg,
  .px-sm-lg {
    padding-right: 6rem !important;
  }
  .pb-sm-lg,
  .py-sm-lg {
    padding-bottom: 6rem !important;
  }
  .pl-sm-lg,
  .px-sm-lg {
    padding-left: 6rem !important;
  }
  .p-sm-xl {
    padding: 8rem !important;
  }
  .pt-sm-xl,
  .py-sm-xl {
    padding-top: 8rem !important;
  }
  .pr-sm-xl,
  .px-sm-xl {
    padding-right: 8rem !important;
  }
  .pb-sm-xl,
  .py-sm-xl {
    padding-bottom: 8rem !important;
  }
  .pl-sm-xl,
  .px-sm-xl {
    padding-left: 8rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-sm {
    margin: 2rem !important;
  }
  .mt-md-sm,
  .my-md-sm {
    margin-top: 2rem !important;
  }
  .mr-md-sm,
  .mx-md-sm {
    margin-right: 2rem !important;
  }
  .mb-md-sm,
  .my-md-sm {
    margin-bottom: 2rem !important;
  }
  .ml-md-sm,
  .mx-md-sm {
    margin-left: 2rem !important;
  }
  .m-md-md {
    margin: 4rem !important;
  }
  .mt-md-md,
  .my-md-md {
    margin-top: 4rem !important;
  }
  .mr-md-md,
  .mx-md-md {
    margin-right: 4rem !important;
  }
  .mb-md-md,
  .my-md-md {
    margin-bottom: 4rem !important;
  }
  .ml-md-md,
  .mx-md-md {
    margin-left: 4rem !important;
  }
  .m-md-lg {
    margin: 6rem !important;
  }
  .mt-md-lg,
  .my-md-lg {
    margin-top: 6rem !important;
  }
  .mr-md-lg,
  .mx-md-lg {
    margin-right: 6rem !important;
  }
  .mb-md-lg,
  .my-md-lg {
    margin-bottom: 6rem !important;
  }
  .ml-md-lg,
  .mx-md-lg {
    margin-left: 6rem !important;
  }
  .m-md-xl {
    margin: 8rem !important;
  }
  .mt-md-xl,
  .my-md-xl {
    margin-top: 8rem !important;
  }
  .mr-md-xl,
  .mx-md-xl {
    margin-right: 8rem !important;
  }
  .mb-md-xl,
  .my-md-xl {
    margin-bottom: 8rem !important;
  }
  .ml-md-xl,
  .mx-md-xl {
    margin-left: 8rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-sm {
    padding: 2rem !important;
  }
  .pt-md-sm,
  .py-md-sm {
    padding-top: 2rem !important;
  }
  .pr-md-sm,
  .px-md-sm {
    padding-right: 2rem !important;
  }
  .pb-md-sm,
  .py-md-sm {
    padding-bottom: 2rem !important;
  }
  .pl-md-sm,
  .px-md-sm {
    padding-left: 2rem !important;
  }
  .p-md-md {
    padding: 4rem !important;
  }
  .pt-md-md,
  .py-md-md {
    padding-top: 4rem !important;
  }
  .pr-md-md,
  .px-md-md {
    padding-right: 4rem !important;
  }
  .pb-md-md,
  .py-md-md {
    padding-bottom: 4rem !important;
  }
  .pl-md-md,
  .px-md-md {
    padding-left: 4rem !important;
  }
  .p-md-lg {
    padding: 6rem !important;
  }
  .pt-md-lg,
  .py-md-lg {
    padding-top: 6rem !important;
  }
  .pr-md-lg,
  .px-md-lg {
    padding-right: 6rem !important;
  }
  .pb-md-lg,
  .py-md-lg {
    padding-bottom: 6rem !important;
  }
  .pl-md-lg,
  .px-md-lg {
    padding-left: 6rem !important;
  }
  .p-md-xl {
    padding: 8rem !important;
  }
  .pt-md-xl,
  .py-md-xl {
    padding-top: 8rem !important;
  }
  .pr-md-xl,
  .px-md-xl {
    padding-right: 8rem !important;
  }
  .pb-md-xl,
  .py-md-xl {
    padding-bottom: 8rem !important;
  }
  .pl-md-xl,
  .px-md-xl {
    padding-left: 8rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-sm {
    margin: 2rem !important;
  }
  .mt-lg-sm,
  .my-lg-sm {
    margin-top: 2rem !important;
  }
  .mr-lg-sm,
  .mx-lg-sm {
    margin-right: 2rem !important;
  }
  .mb-lg-sm,
  .my-lg-sm {
    margin-bottom: 2rem !important;
  }
  .ml-lg-sm,
  .mx-lg-sm {
    margin-left: 2rem !important;
  }
  .m-lg-md {
    margin: 4rem !important;
  }
  .mt-lg-md,
  .my-lg-md {
    margin-top: 4rem !important;
  }
  .mr-lg-md,
  .mx-lg-md {
    margin-right: 4rem !important;
  }
  .mb-lg-md,
  .my-lg-md {
    margin-bottom: 4rem !important;
  }
  .ml-lg-md,
  .mx-lg-md {
    margin-left: 4rem !important;
  }
  .m-lg-lg {
    margin: 6rem !important;
  }
  .mt-lg-lg,
  .my-lg-lg {
    margin-top: 6rem !important;
  }
  .mr-lg-lg,
  .mx-lg-lg {
    margin-right: 6rem !important;
  }
  .mb-lg-lg,
  .my-lg-lg {
    margin-bottom: 6rem !important;
  }
  .ml-lg-lg,
  .mx-lg-lg {
    margin-left: 6rem !important;
  }
  .m-lg-xl {
    margin: 8rem !important;
  }
  .mt-lg-xl,
  .my-lg-xl {
    margin-top: 8rem !important;
  }
  .mr-lg-xl,
  .mx-lg-xl {
    margin-right: 8rem !important;
  }
  .mb-lg-xl,
  .my-lg-xl {
    margin-bottom: 8rem !important;
  }
  .ml-lg-xl,
  .mx-lg-xl {
    margin-left: 8rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-sm {
    padding: 2rem !important;
  }
  .pt-lg-sm,
  .py-lg-sm {
    padding-top: 2rem !important;
  }
  .pr-lg-sm,
  .px-lg-sm {
    padding-right: 2rem !important;
  }
  .pb-lg-sm,
  .py-lg-sm {
    padding-bottom: 2rem !important;
  }
  .pl-lg-sm,
  .px-lg-sm {
    padding-left: 2rem !important;
  }
  .p-lg-md {
    padding: 4rem !important;
  }
  .pt-lg-md,
  .py-lg-md {
    padding-top: 4rem !important;
  }
  .pr-lg-md,
  .px-lg-md {
    padding-right: 4rem !important;
  }
  .pb-lg-md,
  .py-lg-md {
    padding-bottom: 4rem !important;
  }
  .pl-lg-md,
  .px-lg-md {
    padding-left: 4rem !important;
  }
  .p-lg-lg {
    padding: 6rem !important;
  }
  .pt-lg-lg,
  .py-lg-lg {
    padding-top: 6rem !important;
  }
  .pr-lg-lg,
  .px-lg-lg {
    padding-right: 6rem !important;
  }
  .pb-lg-lg,
  .py-lg-lg {
    padding-bottom: 6rem !important;
  }
  .pl-lg-lg,
  .px-lg-lg {
    padding-left: 6rem !important;
  }
  .p-lg-xl {
    padding: 8rem !important;
  }
  .pt-lg-xl,
  .py-lg-xl {
    padding-top: 8rem !important;
  }
  .pr-lg-xl,
  .px-lg-xl {
    padding-right: 8rem !important;
  }
  .pb-lg-xl,
  .py-lg-xl {
    padding-bottom: 8rem !important;
  }
  .pl-lg-xl,
  .px-lg-xl {
    padding-left: 8rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl-sm {
    margin: 2rem !important;
  }
  .mt-xl-sm,
  .my-xl-sm {
    margin-top: 2rem !important;
  }
  .mr-xl-sm,
  .mx-xl-sm {
    margin-right: 2rem !important;
  }
  .mb-xl-sm,
  .my-xl-sm {
    margin-bottom: 2rem !important;
  }
  .ml-xl-sm,
  .mx-xl-sm {
    margin-left: 2rem !important;
  }
  .m-xl-md {
    margin: 4rem !important;
  }
  .mt-xl-md,
  .my-xl-md {
    margin-top: 4rem !important;
  }
  .mr-xl-md,
  .mx-xl-md {
    margin-right: 4rem !important;
  }
  .mb-xl-md,
  .my-xl-md {
    margin-bottom: 4rem !important;
  }
  .ml-xl-md,
  .mx-xl-md {
    margin-left: 4rem !important;
  }
  .m-xl-lg {
    margin: 6rem !important;
  }
  .mt-xl-lg,
  .my-xl-lg {
    margin-top: 6rem !important;
  }
  .mr-xl-lg,
  .mx-xl-lg {
    margin-right: 6rem !important;
  }
  .mb-xl-lg,
  .my-xl-lg {
    margin-bottom: 6rem !important;
  }
  .ml-xl-lg,
  .mx-xl-lg {
    margin-left: 6rem !important;
  }
  .m-xl-xl {
    margin: 8rem !important;
  }
  .mt-xl-xl,
  .my-xl-xl {
    margin-top: 8rem !important;
  }
  .mr-xl-xl,
  .mx-xl-xl {
    margin-right: 8rem !important;
  }
  .mb-xl-xl,
  .my-xl-xl {
    margin-bottom: 8rem !important;
  }
  .ml-xl-xl,
  .mx-xl-xl {
    margin-left: 8rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl-sm {
    padding: 2rem !important;
  }
  .pt-xl-sm,
  .py-xl-sm {
    padding-top: 2rem !important;
  }
  .pr-xl-sm,
  .px-xl-sm {
    padding-right: 2rem !important;
  }
  .pb-xl-sm,
  .py-xl-sm {
    padding-bottom: 2rem !important;
  }
  .pl-xl-sm,
  .px-xl-sm {
    padding-left: 2rem !important;
  }
  .p-xl-md {
    padding: 4rem !important;
  }
  .pt-xl-md,
  .py-xl-md {
    padding-top: 4rem !important;
  }
  .pr-xl-md,
  .px-xl-md {
    padding-right: 4rem !important;
  }
  .pb-xl-md,
  .py-xl-md {
    padding-bottom: 4rem !important;
  }
  .pl-xl-md,
  .px-xl-md {
    padding-left: 4rem !important;
  }
  .p-xl-lg {
    padding: 6rem !important;
  }
  .pt-xl-lg,
  .py-xl-lg {
    padding-top: 6rem !important;
  }
  .pr-xl-lg,
  .px-xl-lg {
    padding-right: 6rem !important;
  }
  .pb-xl-lg,
  .py-xl-lg {
    padding-bottom: 6rem !important;
  }
  .pl-xl-lg,
  .px-xl-lg {
    padding-left: 6rem !important;
  }
  .p-xl-xl {
    padding: 8rem !important;
  }
  .pt-xl-xl,
  .py-xl-xl {
    padding-top: 8rem !important;
  }
  .pr-xl-xl,
  .px-xl-xl {
    padding-right: 8rem !important;
  }
  .pb-xl-xl,
  .py-xl-xl {
    padding-bottom: 8rem !important;
  }
  .pl-xl-xl,
  .px-xl-xl {
    padding-left: 8rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
